<template>
  <van-popup
      :value="value"
      :overlayStyle="{ background: 'rgba(20, 21, 30, 0.6)' }"
      :style="{ width: '100%', maxHeight: '80%', minHeight:'35%', background: '#1E232D', padding: '0 10px' }"
      :transition-appear=true
      position="bottom"
      duration="0.5"
      @input="val => this.$emit('input', val)"
      @close='closePop'
      round
  >
    <div class="title">
      {{ $t('userDetail.updatePwd')}}
    </div>
    <div class="pop-body">
      <div class="content">
        <div class="input-wrap">
          <img src="@/assets/img/user/login-pwd.svg" />
          <input v-model="oldPwd" type="password" :placeholder="$t('userDetail.oldPwdHolder')" />
        </div>
        <div class="input-wrap">
          <img src="@/assets/img/user/login-pwd.svg" />
          <input v-model="newPwd" type="password" :placeholder="$t('userDetail.newPwdHolder')" />
        </div>
        <div class="input-wrap">
          <img src="@/assets/img/user/login-pwd.svg" />
          <input v-model="cfmPwd" type="password" :placeholder="$t('userDetail.confirmPwdHolder')" />
        </div>
        <div class="btn-wrap">
          <van-button :loading="loading" class="btn" type="primary" @click="updatePwd">{{ $t('common.sure') }}</van-button>
        </div>
      </div>

      <div class="botton-white-line "></div>
    </div>

  </van-popup>

</template>

<script>
import { updatePassword } from '@/service/user'
export default {
  name: 'EditPassword',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      oldPwd: '',
      newPwd: '',
      cfmPwd: '',
      loading: false
    }
  },
  methods: {
    closePop() {
      this.oldPwd = ''
      this.newPwd = ''
      this.cfmPwd = ''
      this.$emit('input', false)
    },
    async updatePwd() {
      if (!this.oldPwd) {
        this.$toast('请输入旧密码')
        return
      }
      if (!this.newPwd) {
        this.$toast('请输入新密码')
        return
      }
      console.log(this.newPwd.length)
      if (this.newPwd.length < 6 || this.newPwd.length > 20) {
        this.$toast('密码长度为6-20位')
        return
      }
      if (!this.cfmPwd) {
        this.$toast('请输入确认密码')
        return
      }
      if (this.newPwd !== this.cfmPwd) {
        this.$toast('两次密码输入不一致')
        return
      }
      this.loading = true
      const resp = await updatePassword({
        oldPassword: this.oldPwd,
        password: this.newPwd
      })
      this.loading = false
      if (resp.success) {
        this.$toast('修改成功')
        this.$emit('input', false)
      } else {
        this.$toast(resp.code)
      }
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>

.title {
  font-size: 20px;
  display: flex;
  padding: 0 10px;
  margin: 20px 0;
  font-weight: 600;
}

.pop-body {
  .content {
    width: 100%;
    margin-top: 20px;
    .input-wrap {
      width: 100%;
      display: flex;
      padding: 0 10px;
      margin-bottom: 20px;
      position: relative;
      input {
        width: 100%;
        min-height: 40px;
        background: #14151E;
        padding-left: 46px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: bold;
      }
      img {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 12px;
        left: 20px;
        filter: brightness(200%);
      }
    }

    .btn-wrap {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 24px;
      padding-bottom: 30px;
      margin-bottom: 64px;
      .btn {
        background-color: #524774;
        border-color: #524774;
        height: 38px;
        line-height: 38px;
        width: 200px;
        border-radius: 4px;
      }
    }
  }
  .curr-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 10px;
    font-size: 14px;
    >div {
      display: flex;
      align-items: center;
      >p {
        margin-left: 10px;
        font-weight: 600;
      }
    }
  }
  .btn-groups {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    .btn {
      width: 45%;
      height: 35px;
    }
  }
}

/deep/.van-button--normal {
  padding: 0 15px;
  font-size: 14px;
}

/deep/.van-button__text {
  font-weight: bold;
}

</style>