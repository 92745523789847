<template>
  <div class="pt-nav">
    <UserBackground :userId="userInfo.userId" />
    <div class="user-wrap">
      <!-- 用户卡片 -->
      <UserCard :type="'2'" :userInfo="userInfo" @handleEditClick="handleToEditInfo" @handleEditPwd="handleToEditPwd" />
      <div class="line"></div>
      <!-- 相册 -->
      <UserPhotos :photoList="photoList" @editPhotos="submitEditPhotos" />

      <!-- 修改密码弹窗 -->
      <EditPasswordPop v-model="pwdPopShow" />
    </div>
  </div>
</template>

<script>
import UserBackground from '../../components/user/UserBackground.vue';
import UserCard from '../../components/user/UserCard.vue'
import UserPhotos from '../../components/user/UserPhotos.vue'
import EditPasswordPop from "@/views/personal/EditPasswordPop.vue";
import { mapActions, mapState } from 'vuex'
import { editUserPhotos } from '@/service/user'
import $gbUtils from "@/utils/gbUtils";
export default {
  name: 'Personal',
  components: { UserBackground, UserCard, UserPhotos, EditPasswordPop },
  data() {
    return {
      pwdPopShow: false,
      photoList: []
    }
  },
  computed: {
    ...mapState(['userInfo']),
    $gbUtils() {
      return $gbUtils
    }
  },
  methods: {
    ...mapActions(['changeUserInfo']),
    init() {
      this.formatData()
    },
    handleToEditInfo() {
      this.$gbUtils.handleToPage('/personalEdit')
    },
    handleToEditPwd() {
      this.pwdPopShow = true
    },
    // 相册修改
    async submitEditPhotos(photos) {
      const params = {
        photos: photos
      }
      const resp = await editUserPhotos(params)
      if (!resp.success) {
        // 重置相册数据
        this.$nextTick(() => {
          this.photoList = []
          this.formatData()
        })
        return this.$toast('修改失败')
      } else {
        this.userInfo.photos = photos;
        this.formatData();
        await this.changeUserInfo(this.userInfo)
      }
    },
    // 数据格式化
    formatData() {
      // 图片处理
      if (this.userInfo.photos && this.userInfo.photos !== '') {
        this.photoList = (this.userInfo.photos).split(',').map(item => {
          return {
            url: this.$gbUtils.formatImgUrl(item)
          }
        })
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.user-wrap {
  position: relative;
  z-index: 1;
}
</style>
